import React from "react";
import Layout from "../components/Layout";
import CookieStyles from "./cookie.module.scss";

function cookie() {
  return (
    <Layout>
      <div className={CookieStyles.container}>
        <h1>Cookie Policy</h1>
        <ul>
          <li>
            <p>
              Hashout website uses cookies to help optimize the visitor’s
              browsing experience. Learn more about cookies and how you can
              control them below.
            </p>
            <p>
              Cookies are small text files used by websites and stored on your
              devices, with your consent, that remember your visit, help you
              resume where your last browsing session left off, display pages
              correctly, and keep track of your preferences within the website.
            </p>
          </li>
          <li>
            <h3>
              We maybe using the following during different customer journeys
            </h3>
            <p>
              <b>Registration cookies</b> - which will help us to identify the
              account you are signed in with and review your permissions for
              particular service, and they help us associate any comments you
              post with your username.
            </p>
            <p>
              <b>Site performance cookies</b> - helps us to remember your
              preferences on our website so you have easy access to them each
              time you visit our site.
            </p>
            <p>
              <b>Anonymous analytics cookies</b> - tell us whether or not
              someone has visited our site in the past so we can track the
              number of site users and how often they visit for statistics. Note
              that these cookies can’t be used to identify any individual.
            </p>
            <p>
              <b>Advertising cookies</b> - help us to target our advertisement
              more accurately and track user interactions on our ads. These
              cookies store user interactions and not personal information.
            </p>
          </li>
          <li>
            <h3>How to accept, reject or turn off cookies</h3>
            <p>
              You can accept or reject cookies on our website via the pop-up
              cookies banner on our homepage or by emailing us at&nbsp;
              <a href="mailto:contact@hashout.com">
                contact@hashout.com
              </a>
              .
            </p>
            <p>
              You can turn off cookies from all the websites you visit, via your
              browser’s settings. You can follow the suggestions below
              <ul className={CookieStyles.styleList}>
                <li>
                  Cookies settings in{" "}
                  <a href="https://support.google.com/chrome/answer/95647?hl=en&ref_topic=14666">
                    Chrome
                  </a>
                  ,{" "}
                  <a href="https://support.mozilla.com/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&redirectslug=Cookies">
                    Firefox
                  </a>
                  ,{" "}
                  <a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-10">
                    Internet Explorer
                  </a>
                  ,{" "}
                  <a href="https://support.apple.com/kb/PH17191?locale=en_US">
                    Safari
                  </a>{" "}
                  and <a href="https://support.apple.com/en-us/HT201265">iOS</a>
                </li>
              </ul>
            </p>
          </li>
        </ul>
      </div>
    </Layout>
  );
}

export default cookie;
